@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-journey-header-row {
    display:flex;
    flex-direction:row;
}
.fx-comp-journey-header-item {
    width:125px;
    padding:var(--padding-med);
    box-sizing: border-box;
    border:1px solid #f1f1f1;
}
.fx-comp-journey-path-row {
    display:flex;
    flex-direction: row;
}

.fx-comp-swimlane-flow-detail-path-row {
    display:flex;
    flex-direction: row;
}
.fx-comp-swimlane-flow-detail-row-starter {
    display:flex;
    flex-direction:column;
    width:125px;
}
.fx-comp-swimlane-flow-detail-row-item {
    display:flex;
    flex-direction:column;
    width:125px;
}
.fx-comp-swimlane-flow-add-container {
    text-align: center;
}
.fx-comp-journey-select-container {
    margin-top:16px;
    margin-bottom:16px;
}