@import '../CSSLibrary/UXMGRVariables.css';

.timeline-container {
    display: grid;
    gap: 20px;
    padding: 20px;
    width: 100%;
    overflow-x: hidden;
  }
  
  .controls {
    display: flex;
    align-items: center;
    gap: 20px;
    margin:0 auto;
  }
  
  .date-range {
    display: grid;
    gap: 10px;
    text-align: center;
    position: relative;
  }
  
  .date {
    padding: 10px;
    background-color: white;
    border: 1px solid #ddd;
    font-size: 14px;
    position: relative;
    border-radius:4px;
  }
  
  .today-marker {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    background-color: rgb(195, 195, 195);
  }
  
  .milestones {
    display: grid;
    gap: 10px;
    position: relative;
    height: 40px;
    background-color:#eda35e2e;
    padding:10px;
  }
  
  .milestone {
    padding: 5px;
    background-color: #eda35e;
    color: white;
    text-align: center;
    border-radius: 4px;
    font-size: 14px;
    cursor:pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .fx-timeline-datepicker-control {
    background-color: white;
  }
  