@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-header-appbar {
    background-color:var(--uxc-color-blue-dark) !important;
    border-bottom:1px solid white;
}
.fx-comp-header-appbar > div {
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.fx-comp-header-appbar div {
    display: flex;
}
.fx-comp-header-appbar button {
    color:white;
}

.fx-hide-menu {
    display:none !important;
}
.fx-comp-header-profile-name {
    margin-top:8px;
    margin-right:8px;
    font-weight:bold;
    font-size:14px;
    text-transform: uppercase;
}
.fx-comp-header-metric-item {
    display:flex;
    flex-direction: column;
    align-items: center;
    padding-right:var(--padding-lg);
    padding-left:var(--padding-lg);
    border-right:1px solid white;
}

.fx-comp-header-metric-primetext {
    font-size:32px;
    font-weight:bold;
}
.fx-comp-header-metric-subtext {
    font-size:10px;
}

.fx-comp-header-link {
    margin-top:10px !important;
}

.fx-comp-header-profile-name {
    margin-top:18px !important;
}
.fx-comp-header-hidden {
    display:none !important;
}