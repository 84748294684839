.fx-comp-page-temp-utility-center-outer {
    
    margin:0 auto;
}

.fx-comp-page-temp-utility-center-outer.max-width-25 {
    width:25%;
}

.fx-comp-page-temp-utility-center-outer.max-width-40 {
    width:40%;
}

.fx-comp-page-temp-utility-center-inner {
    border: 1px solid #f1f1f1;
    padding:16px;
    border-radius:4px;
}