@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-startsetup-step-container-inner {
    text-align: left;
    max-width:80%;
    margin:0 auto;
}
.fx-comp-startsetup-step-container-inner input {
    width:100%;
}
.fx-comp-startsetup-step-container-inner .MuiOutlinedInput-root {
    background-color: white;
}
.fx-comp-startsetup-step-vision-statement-wrap, .fx-comp-startsetup-step-objective-wrap {
    padding-top:16px;
    padding-bottom:16px;
}
.fx-comp-start-setup-step-left-panel-body {
    color:white;
    font-size:24px;
    font-weight:100;
}
.fx-comp-startsetup-step-action-button-row-right-only {
    display: flex;
    flex-direction: row-reverse;
}
.fx-comp-startsetup-step-action-button-full-row {
    display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top:16px;
}
.fx-comp-startsetup-step-action-left-button {
    flex: 1;
    text-align: left;
  }
  
.fx-comp-startsetup-step-action-right-buttons {
flex: 1;
display: flex;
justify-content: flex-end;
gap: 10px; /* Adjust spacing between buttons */
}

.fx-comp-startsetup-step-team-member-row {
    display:flex;
    flex-direction:row;
    max-width:600px;
    column-gap: 16px;
}

.fx-comp-startsetup-step-team-member-row-item-1 {
    flex-grow:1;
}
.fx-comp-startsetup-step-team-member-row-item-2 {
    flex-grow:0;
}
.fx-comp-startsetup-status-appeal-chip-row {
    display:flex;
    flex-direction:row-reverse;
}
.fx-comp-startsetup-status-appeal-response-desc {
    padding-left:16px !important;
}