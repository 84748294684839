.fx-comp-timeline-minimal-container {
    width: 100%;
    padding: 10px;
    background-color: #f9f9f9;
  }
  
  .fx-comp-timeline-minimal-row {
    position: relative;
  }
  
  .fx-comp-timeline-minimal-milestone {
    background-color: #cccccc;
    border-radius: 4px;
    border:1px solid #aaaaaa;
    cursor:pointer;
  }

  .fx-comp-timeline-minimal-toolbar-thin {
    display: flex;
    flex-direction:row;
}
  