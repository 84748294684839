@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-dash-pb-gallery-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 items per row */
    grid-gap: 10px;
  }
  
  .fx-comp-dash-pb-gallery-item {
    text-align: center;
  }
  
  /*
  .fx-create-item {
    background-color: #ffcd2c;
  }
  */
  .fx-comp-dash-pb-gallery-show-button {
    margin-top:16px !important;
  }
  .fx-create-item-plus {
    font-size:50px;
  }

