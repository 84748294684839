.board-container {
    display: flex;
    overflow-x: auto;
    padding: 20px;
    white-space: nowrap;
  }
  
  .anchor-container {
    flex: 0 0 300px;
    margin-right: 20px;
    background-color: #ffffff !important;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    min-height: 400px;
  }
  
  .card {
    background: white;
    border:1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .add-anchor-button {
    background: #007bff;
    color: white;
    border: none;
    padding: 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
  }

  .fx-comp-objective-anchor-title-row {
    display:flex;
    flex-direction:row;
  }

  .fx-comp-objective-anchor-type-icon {
    height: max-content;
  }

  .fx-comp-objective-anchor-type-icon svg {
    width:20px;
    height: auto;
    padding:5px;
    display:flex;
  }

  .fx-comp-objective-anchor-title {
    display: flex;
    flex-grow:1;
  }

  .fx-comp-objective-anchor-type-icon.assignment {
    background-color:#edf7ed;
    margin-top:var(--padding-lg);
    margin-right:var(--padding-med);
  }

  .fx-comp-objective-anchor-type-icon.decision {
    background-color:#fff4e5;
    margin-top:var(--padding-lg);
    margin-right:var(--padding-med);
  }

  .fx-comp-objective-anchor-title-type {
    color:var(--uxc-color-blue-teal);
    font-style:italic;
    margin-left:38px;
    margin-top:-10px;
    margin-bottom:16px;
  }

  .fx-objective-card-title-row {
    display:flex;
    flex-direction:row;
  }

  .fx-objective-card-title-row-title-portion {
    display:flex;
    flex-grow:1;
  }

  .fx-objective-card-title-row-button-portion {
    display:flex;
    flex-grow:0;
  }
