@import '../CSSLibrary/UXMGRVariables.css';

.fx-crud-modal-objective-view-row {
    display: flex;
    flex-direction: row;
}
.fx-crud-modal-objective-view-row > div {
    margin-right:var(--padding-lg);
}

.fx-objective-detail-slider-wrap {
    width:200px;
}




