@import '../CSSLibrary/UXMGRVariables.css';

[role="combobox"] {
    background: white;
}

.fx-comp-ui-assets-gallery-container {
    display:flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between items as needed */
}
.fx-comp-ui-assets-gallery-item {
    display:flex;
    width:200px;
    height:200px;
}
.fx-comp-ui-assets-gallery-picker {
    width:250px;
}