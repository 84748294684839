@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-nav-item-outer {
    margin-bottom:var(--padding-med);
}

.fx-comp-nav-item-outer > div {
    border-radius:var(--border-radius-default);
    display:inline-flex;
    width:100%;
}
.fx-comp-nav-item-outer .strong {
    background-color:var(--uxc-color-purple-dark);
    color:white;
}

.fx-comp-nav-item-outer .default {
    background-color:white;
    color:#7D8FB3;
}

.fx-comp-nav-item-outer .default .fx-comp-nav-item-action-expand-icon {
    color:#7D8FB3;
}

.fx-comp-nav-item-outer .strong, .fx-comp-nav-item-outer .default {
    display:flex;
    flex-direction:column;
}
.fx-comp-nav-item-id-group {
    flex-grow:1;
    display:flex;
    padding-top:var(--padding-med);
}
.fx-comp-nav-item-action-group {
    display:inline-flex;
}
.fx-comp-nav-item-action-notify {
    background-color:rgba(255,255,255,0.2);
    padding:var(--padding-med);
    border-radius:50px;
}
.fx-comp-nav-item-action-expand-icon {
    margin-top:var(--padding-med);
    background-color:transparent;
    border:none;
    color:white;
    cursor: pointer;
}
.fx-comp-nav-item-action-title {
    padding-left:var(--padding-med);
}
.fx-comp-nav-item-top-outer {
    display: inline-flex;
    padding:var(--padding-med);
}
.fx-comp-nav-item-detail-panel {
    padding:var(--padding-med);
    display: flex;
    flex-direction:column;
}
.fx-comp-nav-item-detail-panel a {
    color:#7D8FB3;
    text-decoration: none;
}
.strong .fx-comp-nav-item-detail-panel a {
    color:white;
}
.fx-comp-nav-item-detail-panel svg {
    vertical-align: middle;
    padding-right:6px;
}

