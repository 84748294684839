@import '../CSSLibrary/UXMGRVariables.css';

.fx-page-start-outer-container {
    display: flex;
    flex-direction:column;
}

.fx-page-start-inner-container {
    display: flex;
    max-width:60%;
    margin:0 auto;
    flex-direction: column;
}

.fx-page-start-inner-container h1 {
    display:flex;
    margin:0 auto;
}

.fx-page-auth-login-login-form-wrap .fx-global-form-row, .fx-page-auth-login-register-form-wrap .fx-global-form-row {
    width:100%;
    display:flex;
}
.fx-global-form-row.fx-page-auth-login-form-names {
    display:flex;
    flex-direction:row;
    column-gap: 16px;
}

.fx-page-start-hr {
    border:1px solid #f1f1f1;
    margin:25px;
}

.fx-page-start-login-wrap {
    display: flex;
}

.fx-page-start-learn-more-wrap {
    display: flex;
    flex-direction: column;
}

.fx-page-start-login-wrap Button {
    margin:0 auto;
}

.fx-page-start-intro-wrap {
    text-align: left;
}

.fx-page-start-heading-wrap {
    display: flex;
    justify-content: center;
}

.fx-page-start-login-splash-wrap {
    display:flex;
}

.fx-page-start-login-splash-wrap img {
    margin:0 auto;
}

.fx-page-start-logo-container {
    display:flex;
    flex-direction:row;
    background-color: var(--uxc-color-blue-dark);
}

.fx-page-start-logo-container img {
    margin:0 auto;
}

.fx-page-start-logo-container img {
    margin:0 auto;
}