@import '../CSSLibrary/UXMGRVariables.css';

.uxmgr-comp-pagetempmainsidenav-sidnav-outer {
    border:1px solid #f1f1f1;
}
.uxf-comp-sidenav-list {
    margin-right:16px !important;
}

.uxmgr-comp-pagetempmainsidenav-list-leveltwo {
    padding-left:16px !important;
}

.uxmgr-comp-pagetempmainsidenav-list-levelthree {
    padding-left:32px !important;
}
.uxi-comp-sidenav-hidden {
    display: none;
}
.uxi-comp-sidenav-reset-test-data-link {
    color:#1976d2;
    text-decoration:underline;
    cursor:pointer;
}
.uxi-com-sidenav-no-padding {
    padding:0px !important;
}

.fx-comp-side-nav-collapse-container {
    background-color: white;
    border-radius:var(--border-radius-default);
    width:100%;
}
.fx-comp-side-nav-master-link-primary-group-item {
    margin-bottom:var(--padding-med) !important;
    border-radius:4px !important;
    padding:var(--padding-med) !important;
    border:1px solid #292445;
    background:white !important;
}
.fx-comp-side-nav-master-link-primary-group-item.nested {
    background:transparent !important;
    padding:0px !important;
}
.fx-comp-side-nav-master-link-primary-group-item a {
    text-decoration: none !important;
}

.fx-comp-side-nav-master-link-primary-group-item-inner {
    color:#292445;
    
}