@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-crud-form-object-link-select-anchor-wrap {
    display:flex;
    flex-direction: column;
}

.fx-comp-crud-form-object-link-select-anchor-wrap label {
    font-size:16px;
    font-family:'Open Sans';
}
.fx-comp-crud-form-object-link-select-anchor-wrap select {
    height:56px;
    font-size:16px;
    font-family:'Open Sans';
    border-color:#cccccc;
    border-radius:4px;
}

