@import '../CSSLibrary/UXMGRVariables.css';

.uxc-comp-content-block {
    padding:var(--padding-lg);
}
.uxc-comp-content-block-bg-darkblue {
    background-color:var(--uxc-color-blue-dark);
}
.uxc-comp-content-block-bg-darkblue h2 {
    color:white;
    font-size:2.25rem;
    font-weight:500;
}
.uxc-comp-content-block-bg-white {
    background-color:white;
}
.uxc-comp-content-block-bg-white h2 {
    color:var(--uxc-color-blue-dark);
    font-size:2.25rem;
    font-weight:500;
}

.uxc-variant-read-only-block {
    border:1px solid #f1f1f1;
    padding:var(--padding-lg);
}

.uxc-comp-content-block-read-only-header {
    margin-left:22px;
}