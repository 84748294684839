@import '../CSSLibrary/UXMGRVariables.css';

.fx-page-dashboard-action-pill-done {
    background-color:#B5E742 !important;
}

.fx-page-dashboard-action-pill-working {
    background-color:#EDA35E !important;
    color:white !important;
}

.fx-page-dashboard-action-pill-done, .fx-page-dashboard-action-pill-working {
    margin-right:4px;
    margin-left:4px;
}