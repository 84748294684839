@import '../CSSLibrary/UXMGRVariables.css';

.container {
    padding: '20px';
}
.avatarContainer {
    text-align: 'right';
}
.contentBlock {
    margin-top: '20px';
    padding: '20px';
}
.slider {
    width: '100%';
}