thead tr {
    background-color: #D9D9D9;
}
.fx-page-dashboard-table th, .fx-page-dashboard-table td {
    text-align:left;
}

.fx-page-dashboard-action-pill-done {
    background-color:#B5E742 !important;
}

.fx-page-dashboard-action-pill-working {
    background-color:#EDA35E !important;
    color:white !important;
}