.fx-comp-startsetup-step-container-inner {
    text-align: left;
    max-width:80%;
    margin:0 auto;
}

.fx-comp-start-setup-step-left-panel-body {
    color:white;
    font-size:24px;
    font-weight:100;
}

.fx-comp-startsetup-pending-org-message-wrap {
    text-align:center;
}