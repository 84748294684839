@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-objective-anchor-card-toggle-row {
    display:flex;
    flex-direction:row-reverse;
    column-gap: var(--padding-med);
    padding-top: var(--padding-med);
    padding-bottom: var(--padding-med);
}
.fx-comp-objective-anchor-card-toggle-row-label {
    padding-top:12px;
}

.fx-comp-objective-anchor-card-related-item {
    padding: var(--padding-med);
    border:1px solid #ccc;
    border-radius:5px;
    margin-top: var(--padding-med);
}