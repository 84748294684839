.fx-comp-milestones-toolbar {
    display:flex;
    flex-direction: row-reverse;
}

.gantt-chart-container {
    overflow-x: auto;
    position: relative;
    width: 100%;
    height: 300px;
    cursor: default;
    background: #fff;
  }
  
  .gantt-chart-timeline {
    position: sticky;
    top: 0;
    background: #f4f4f4;
    z-index: 10;
    border-bottom: 1px solid #ddd;
    display: flex;
  }
  
  .gantt-chart-timeline-cell {
    text-align: center;
    padding: 5px;
    border:1px solid #f1f1f1;
    box-sizing: border-box;
    background:#ffffff;
  }
  
  .gantt-chart-rows {
    position: relative;
    height: 100%;
    background:#f1f1f1;
  }
  
  .gantt-chart-row {
    position: relative;
    height: 50px;
    border-bottom: 1px solid #ddd;
    width: 100%;
    box-sizing: border-box;
  }
  
  .gantt-chart-milestone {
    position: absolute;
    top: 10px;
    background-color: var(--uxc-color-blue-dark);
    color: white;
    padding: 5px;
    border-radius: 3px;
    white-space: nowrap;
    cursor: pointer;
  }
  
  .gantt-chart-handle-end {
    position: absolute;
    top: 10px;
    right: 0;
    width: 10px;
    height: 20px;
    background-color: gray;
    cursor: ew-resize;
    border-radius: 3px;
  }
  