@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-swimlane-flow-main-path-container {
    display:flex;
    flex-direction:row;
}

.fx-comp-flow-with-swimlanes-toolbar {
    display:flex;
    flex-direction: row-reverse;
}

.fx-comp-swimlane-flow-main-path-col {
    display:flex;
    flex-direction:column;
    width:125px;
}

.fx-comp-swimlane-flow-main-path-col-heading {
    font-weight: bold;
    padding:var(--padding-med);
    border:1px solid #f1f1f1;
    min-height:60px;
}

.fx-comp-swimlane-flow-main-path-cell {
    border-bottom:1px solid #f1f1f1;
    min-height:120px;
    padding:var(--padding-med);
}

.fx-comp-swimlane-flow-avatar-outer .fx-comp-user-persona-avatar {
    width:85px;
    height:85px;
}

.fx-comp-swimlane-flow-detail-path-row {
    display:flex;
    flex-direction: row;
}
.fx-comp-swimlane-flow-detail-row-starter {
    display:flex;
    flex-direction:column;
    width:100px;
}
.fx-comp-swimlane-flow-detail-row-item {
    display:flex;
    flex-direction:column;
    width:125px;
}
.fx-comp-swimlane-flow-add-container {
    text-align: center;
}