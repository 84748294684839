@import '../CSSLibrary/UXMGRVariables.css';

/*
.fx-comp-feature-card-outer {
    padding:var(--padding-med);
    border:1px solid #ccc;
    border-radius:4px;

}

.fx-comp-feature-card-inner > div {
    display: flex;
    padding-top:var(--padding-med);
}
.fx-comp-feature-card-inner > div:last-child {
    padding-bottom:var(--padding-med);
}

.fx-comp-feature-card-number {
    margin:0 auto !important;
    text-align: center;
    background:red;
    color:white;
    border-radius:50%;
    padding-left:10px;
    padding-right:10px;
    padding-top:6px;
    padding-bottom:6px;
    width:20px;
}

.fx-comp-feature-card-title {
    margin:0 auto;
}

.fx-comp-feature-card-button {
    margin:0 auto !important;
}
*/
.fx-comp-feature-card-outer {
    padding:var(--padding-med);
    border:1px solid #ccc;
    border-radius:4px;
    background-color: white;
}

.fx-comp-feature-card-status-header {
        padding:var(--padding-med);
        border:1px solid #ccc;
        border-radius:4px;
}

.fx-comp-feature-card-content-inner {
    min-height:200px;
}