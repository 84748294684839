@import '../CSSLibrary/UXMGRVariables.css';

.comp-page-master-3panel-column-set {
  display:flex;
  flex-direction:row;
  padding-bottom:50px;
}

.comp-page-master-3panel-col-1-outer, .comp-page-master-3panel-col-2-outer, .comp-page-master-3panel-col-3-outer {
  display: flex;
  flex-direction:column;
  
}

.comp-page-master-3panel-col-1-outer, .comp-page-master-3panel-col-3-outer {
  width:18%;
}

.comp-page-master-3panel-col-2-outer {
  width:64%;
}


.comp-page-master-3panel-col-1, .comp-page-master-3panel-col-3 {
  background-color: var(--uxc-color-blue-dark);
  border-radius: 12px;
  color:white;
  padding: var(--padding-med);
  overflow-y:auto;
  box-sizing: border-box;
}



.comp-page-master-3panel-col-2 {
  padding: var(--padding-lg);
}


.bottom-tray {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: var(--uxc-color-blue-dark);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.comp-page-master-full-column-set {
  display:flex;
  flex-direction: column;
  padding-left:var(--padding-lg);
  padding-right:var(--padding-lg);
  padding-top:var(--padding-lg);
  padding-bottom:50px;
}

/*

.fx-comp-temp-3panel-col1-outer,
.fx-comp-temp-3panel-col3-outer {
  position: sticky;
  top: 0;
  z-index: 1000;
  color:white;
}

.fx-comp-temp-3panel-col3-outer {
    padding: var(--padding-lg);
}

.fx-comp-temp-3panel-col1-outer {
  margin: var(--padding-lg);
  padding: var(--padding-lg);
  background-color: var(--uxc-color-blue-dark);
  border-radius: 12px;
}

.fx-comp-temp-3panel-col3-outer {
  margin: var(--padding-lg);
  background-color: var(--uxc-color-blue-dark);
  border-radius: 12px;
}

.fx-comp-temp-3panel-col2-outer {
  padding: var(--padding-lg);
}

.bottom-tray {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 40px;
  background-color: var(--uxc-color-blue-dark);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}
  */
