@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-component-list-item-li {
    list-style:none;
}
.fx-comp-component-list-item-outer {
    padding:var(--padding-lg);
    border:1px solid #ccc;
    border-radius:4px;
    margin-bottom:var(--padding-med);
    background: white;
}

.fx-comp-component-list-item-row {
    display:flex;
    flex-direction: row;
}

.fx-comp-component-list-item-row-items-left {
    display:flex;
    flex-grow:1;
}

.fx-comp-component-list-item-row-items-right {
    display:flex;
    flex-direction:row;
}

.fx-comp-component-list-item-row-items-avatars {
    display:flex;
    flex-direction:row;
}

.fx-comp-component-list-item-row-items-avatars span {
    padding-right:var(--padding-med);
}

.fx-comp-component-list-item-chip-wrap div {
    margin-left:var(--padding-med);
}

.fx-comp-component-list-item-user-feature-avatar-wrap {
    margin-right:var(--padding-med);
}

.fx-comp-component-list-item-related-component-link {
    margin-right:var(--padding-med);
}
.fx-comp-component-list-item-slider-wrap {
    width:200px;
    margin-right:var(--padding-med);
}
.fx-comp-component-list-item-context {
    display:flex;
    flex-direction: row;
    font-size:12px !important;
}
.fx-comp-component-list-item-context .MuiButtonBase-root {
    margin-bottom:var(--padding-med);
}
.fx-comp-component-list-item-context-label {
    padding-top:5px;
    padding-right:var(--padding-med);
}

.fx-comp-nesting-expand-icon, .fx-comp-nesting-expand-icon {
    color:#7d8fb3;
    background: white;
    border:none;
}
.fx-comp-component-list-item-subtask {
    border:1px solid #ccc;
    border-radius:4px;
    margin-bottom:var(--padding-med);
    padding:var(--padding-med);
}