.fx-activity-history-item {
    display:flex;
    flex-direction: row;
}

.fx-activity-history-item {
    margin-bottom: 16px;
    opacity: 1;
    transform: translateX(0);
    transition: transform 0.5s ease, opacity 0.5s ease;
  }
  
  .fx-activity-history-item.new-item {
    transform: translateX(100%);
    opacity: 0;
    animation: slide-in 0.5s forwards;
  }

  .fx-comp-activity-history-item-message-stack {
    display:flex;
    flex-direction:column;
  }

  .fx-comp-activity-history-item-message-stack-message {
    margin-top:5px;
    margin-bottom:5px;
    border:1px solid #f1f1f1;
    border-radius:4px;
    padding:5px;
  }

  .fx-comp-activity-history-item-message-stack-date {
    font-size:10px;
    color:gray;
  }

  .fx-comp-activity-history-item-message-stack-source {
    display:flex;
    width:fit-content;
    background:var(--uxc-color-blue-dark);
    color:white;
    border-radius:4px;
    font-size:12px;
    padding:5px;
  }

  .fx-comp-activity-history-item-avatar {
    padding-top:35px;
  }
  
  @keyframes slide-in {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }