@import '../CSSLibrary/UXMGRVariables.css';

.report-matrix {
  display: flex;
  flex-direction: column;
}

.matrix-header, .matrix-row {
  display: flex;
}

.matrix-cell {
  border: 1px solid #ccc;
  padding: 8px;
  flex: 1;
  text-align: center;
}

.matrix-cell.pass {
  background-color: #d4edda;
}

.matrix-cell.fail {
  background-color: #f8d7da;
}