@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-component-dash-item-wrap {
    display:flex;
    flex-direction: column;
}

.fx-comp-component-dash-item-row {
    display:flex;
    flex-direction: row;
}
.fx-comp-component-dash-item-image-portion {
    width:200px;
}
.fx-comp-component-dash-item-detail-portion {
    flex-grow:1;
}