.fx-comp-temp-intro-slide-left-panel-outer {
    display: flex;
    align-items: center;  /* Vertically centers the inner panel */
    height: 100vh;        /* Full viewport height */
    background:#292444;
  }
  
  .fx-comp-temp-intro-slide-left-panel-inner {
    text-align: center;    /* Centers content inside the inner panel horizontally */
    margin:0 auto;
  }

  .fx-comp-temp-intro-slide-right-panel-outer {
    display: flex;
    align-items: center;  /* Vertically centers the inner panel */
    height: 100vh;        /* Full viewport height */
  }
  
  .fx-comp-temp-intro-slide-right-panel-inner {
    text-align: center;    /* Centers content inside the inner panel horizontally */
    margin:0 auto;
    width:60rem;
  }

  .fx-comp-temp-intro-slide-logout-button-container {
    position: absolute;
    top:0px;
    right:0px;
    margin:16px;
  }