@import '../CSSLibrary/UXMGRVariables.css';

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:20000;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    position: relative;
    max-width: 800px;
    width: 100%;
  }

  .modal-content h2 {
    color:black;
  }
  
  .modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 1.2rem;
    cursor: pointer;
  }

  .fx-comp-crud-modal-delete-row {
    display: flex;
    flex-direction: row;
  }

  .fx-comp-crud-modal-delete-row > div {
    display:flex;
  }