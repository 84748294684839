@import '../CSSLibrary/UXMGRVariables.css';

.carousel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  flex-direction: column;
}

.carousel-items {
  display: flex;
  gap: 10px; /* Reduced gap between items */
  overflow: hidden;
  width: calc(100% - 80px); /* Deduct the button width from total space */
}

.carousel-item {
  flex: 0 0 24%; /* Fixed 20% width for each item */
  min-width: 24%; /* Ensures the item stays at 20% */
  background-color: #f0f0f0;  
  box-sizing: border-box; /* Ensure padding doesn't affect sizing */
}

.carousel-item-inner {
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border:1px solid #cccccc;
  box-sizing: border-box; /* Ensure padding doesn't affect sizing */
  background-color: white;
}

.carousel-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.carousel-button.left {
  left: 0;
}

.carousel-button.right {
  right: 0;
}

.carousel-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.tag-filter {
  margin-bottom: 20px;
  text-align: center;
}

.tag-filter label {
  font-weight: bold;
  margin-right: 10px;
}

.filter-toolbar {
  border-radius: 6px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border:1px solid #cccccc;
  display:flex;
  column-gap: 10px;
  flex-direction: row;
  padding:var(--padding-lg);
  background-color: white;
  margin-bottom:var(--padding-lg);
}

.filter-toolbar [role="combobox"] {
  min-width:100px;
}

.objective-item-heading-container {
  display:flex;
  flex-direction: row;
  padding:var(--padding-med);
  column-gap: 10px;
}

.fx-objective-header-content-item-heading {
  flex-grow:1;
  display:flex;
  flex-direction:column;
}

.fx-objective-header-content-item-heading-primary {
  font-weight:bold;
}

.fx-objective-header-content-item-heading-secondary {
  color:gray;
}

.objective-item-progress {
  padding-left:var(--padding-med);
  padding-right:var(--padding-med);
}

.fx-objective-header-content-preview-icon {
  color:#aaa;
}