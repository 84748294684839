@import '../CSSLibrary/UXMGRVariables.css';

.fx-page-read-only-group-row {
    display:flex;
    flex-direction:row;
}

.fx-page-read-only-col {
    display: flex;
    flex-direction: column;
    margin-right:var(--padding-lg);
}