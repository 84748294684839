@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-accessibility-report-list-item {
    border:1px solid #f1f1f1;
    padding:var(--padding-med);
    border-radius:4px;
}

.fx-comp-accessibility-report-list-item a {
    text-decoration: none;
}