@import '../CSSLibrary/UXMGRVariables.css';

.fx-crud-modal-objective-view-row {
    display: flex;
    flex-direction: row;
}
.fx-crud-modal-objective-view-row > div {
    margin-right:var(--padding-lg);
}

.fx-objective-detail-slider-wrap {
    width:200px;
}

.fx-comp-crud-form-objective-select-anchor-wrap {
    display:flex;
    flex-direction: column;
}

.fx-comp-crud-form-objective-select-anchor-wrap label {
    font-size:16px;
    font-family:'Open Sans';
}
.fx-comp-crud-form-objective-select-anchor-wrap select {
    height:56px;
    font-size:16px;
    font-family:'Open Sans';
    border-color:#cccccc;
    border-radius:4px;
}
