@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-avatar-chooser-modal-selected-avatar-wrapper {
    width:150px;
    margin:0 auto;
}

.fx-comp-avatar-chooser-modal-button-row {
    display:flex;
    flex-direction: row-reverse;
}

.fx-comp-avatar-chooser-modal-gallery {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .fx-comp-avatar-chooser-modal-gallery-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
  
  .fx-comp-avatar-chooser-modal-gallery-item {
    text-align: center;
    margin: 10px;
  }
  
  .fx-comp-avatar-chooser-modal-selected-avatar-wrapper img {
    border-radius: 50%;
    margin-bottom: 15px;
  }