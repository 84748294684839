.fx-page-activity-toolbar-container {
    display:flex;
    flex-direction:row;
    column-gap: 16px;
    margin-bottom:16px;
}

.fx-page-activity-toolbar-toggleset {
    display:flex;
    flex-direction: column;
}