@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-ui-assets-gallery-container {
    display:flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the gap between items as needed */
    margin-top:var(--padding-lg);
}
.fx-comp-ui-assets-gallery-item {
    display:flex;
    width:200px;
    height:260px;
    border:1px solid #f1f1f1;
    border-radius:4px;
    padding:var(--padding-lg);
    flex-direction: column;
    background:white;
    h4 {
        margin-bottom:var(--padding-med);
        margin-top:0;
    }
}
.fx-comp-ui-assets-gallery-item-inner {
    min-height:200px;
}
.fx-comp-ui-assets-gallery-item-footer {
    display:flex;
    flex-direction: row-reverse;
    margin-top:35px;
}