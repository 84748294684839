@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-global-task-bar-container {
    display:flex;
    flex-direction:row-reverse;
    padding:var(--padding-med);
    border:1px solid #f1f1f1;
    column-gap:var(--padding-med);
    color:var(--uxc-color-blue-dark);
    font-weight:bold;
}

.fx-comp-global-task-bar-totals-item {
    display:flex;
    flex-direction: row;
}

.fx-comp-global-task-bar-totals-number {
    border: 1px solid;
    border-radius: 50px;
    min-width: 20px;
    text-align: center;
    margin-left: var(--padding-med);
    padding: 2px;
}