.fx-objective-table-cell-status.not-started {
    background:#f4e3db;
}

.fx-objective-table-cell-status.in-progress {
    background:#faf3d1;
}

.fx-objective-table-cell-status.review {
    background:#e7f6f8;
}

.fx-objective-table-cell-status.done {
    background:#ecf3ec;
}

.MuiDataGrid-scrollbar {
    z-index: 0;
}