.fx-page-team-item-row {
    display:flex;
    flex-direction:row;
    column-gap: 10px;
    width:100%;
}
.fx-page-team-item {
    display:flex;
}

.fx-page-team-item.item-1 {
    flex-grow:1;
}

.fx-page-team-item.item-2 {
    flex-grow:0;
}

.fx-page-team-item-name-role {
    margin-left:16px;
}