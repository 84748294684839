@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-crud-form-inline {
    display: flex;
    flex-direction:row;
}

.fx-comp-crud-form-column {
    display: flex;
    flex-direction: column;
}