@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-dashboard-metric-card-outer {
    width:250px;
    height:250px;
    background-color:#3DA6DE;
    color:white;
    margin-right:var(--padding-lg);
}
.fx-comp-dashboard-metric-card-id-row {
    display:flex;
    flex-direction:row;
    padding:var(--padding-med);
}
.fx-comp-dashboard-metric-card-metric-top-row {
    display:flex;
    flex-direction:row;
}
.fx-comp-dashboard-metric-card-metric-top-row-item-1 {
    flex-grow:1;
}
.fx-comp-dashboard-metric-card-metric-top-row-item {
    width:33%;
    padding:var(--padding-med);
    text-align: center;
    font-weight:bold;
    font-size:32px;
}
.fx-comp-dashboard-metric-card-metric-middle-feature div {
    margin:0 auto;
    width:25%;
    margin-top:20px;
}
.fx-comp-dashboard-metric-subtext {
    font-size:12px;
}

.fx-comp-dashboard-metric-card-open-button-wrap button {
    background:white;
}

.fx-comp-dashboard-metric-card-actions-button {
    background:transparent;
    border:none !important;
    padding:0px !important;
}

.fx-comp-dashboard-metric-card-actions-button svg {
    color:white;
}