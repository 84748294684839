@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-anchor-toolbar-outer {
    padding:var(--padding-med);
    border:1px solid #f1f1f1;
    display:flex;
    flex-direction:row-reverse;
    border-radius:4px;
}
.fx-anchor-toolbar-personas-container {
    display:flex;
    flex-direction:row-reverse;
    column-gap: var(--padding-med);
}