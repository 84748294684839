@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-acc-list-item-wrap {
    display:flex;
    flex-direction: column;
    border:1px solid #f1f1f1;
    border-radius:4px;
    padding:var(--padding-med);
}

.fx-comp-acc-list-item-title-row {
    display:flex;
    flex-direction: row;
    border-bottom:1px solid #f1f1f1;
}

.fx-comp-acc-list-item-title-row-text-item {
    display:flex;
    flex-grow:1;
}

.fx-comp-acc-list-item-title-row-icon-item {
    display:flex;
    flex-grow:0;
}