/* variables.css */
@import './UXMGRComponentButton.css';
@import './UXMGRComponentUtilities.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

:root {
    /* Colors */
    --uxc-color-blue-dark: #292444;
    --uxc-color-purple-dark: #8833FF;
    --uxc-color-blue-light: #0597F2;
    --uxc-color-blue-teal: #03738C;
    --uxc-color-dark-orange: #FF6633;

    /* Padding and Margin */
    --padding-lg: 18px;
    --padding-med: 9px;
    --margin-lg: 18px;
    --margin-med: 9px;

    /* Borders */
    --border-container-light:1px solid #f1f1f1;
    --border-radius-default:10px;

    /* -- Patterns -- */

    /* -- set defaults for body -- */
    body {
      font-size:14px;
      background-color: white;
      margin:0px !important;
    }

    /* -- fonts - demo */
    /* size variables */
    --uxc-font-size-slide: "24px";

    --demo-font-family: "Open Sans", sans-serif;
    .open-sans {
      font-family: "Open Sans", sans-serif;
      font-optical-sizing: auto;
      font-weight: normal;
      font-style: normal;
      font-variation-settings:
        "wdth" 100;
    }

    h1 {
      font-weight:200;
      font-family:var(--demo-font-family);
    }

    h3 {
      font-weight:200;
    }

    div {
      font-family:var(--demo-font-family);
    }

    ul {
      padding-left:0px;
    }

    /* Controls */
    .fx-control-custom-select {
      height:45px;
      border:1px solid #ccc;
      border-radius:4px;
    }

    [role="combobox"] {
      background:white !important;
    }

    /* Form */
    .fx-global-form-row {
      margin-top:16px;
      margin-bottom:16px;
    }
    .fx-global-form-button-row {
        margin-top:16px;
        display:flex;
        flex-direction: row-reverse;
    }
    .fx-global-form-button {
        margin-left:16px;
    }

    .fx-global-form-text-area-description textarea {
      min-height:200px;
    }

    .fx-global-form-read-only-label {
      color:gray;
    }

    .fx-global-crud-edit-form {
      background:white;
      border-radius:4px;
      padding:var(--padding-med);
    }

  }