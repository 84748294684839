@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-swimlane-card-demo-text {
    font-size:10px;
    display:none;
}

.fx-comp-swimlane-card-notify-text {
    background:#e7f6f8;
    border:1px solid #00bde3;
    border-radius:4px;
    color:#00bde3;
    padding:var(--padding-med);
}