@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-status-card-outer {
    margin-top:var(--padding-lg);
    margin-bottom:var(--padding-lg);
    border-bottom:1px solid #ccc;
    padding:var(--padding-lg);
    background-color: white;
    border-radius:6px;
    border:1px solid #ccc;
}

.fx-comp-quick-create-status-form {
    color:black;
}

.fx-comp-status-card-title-line {
    color:gray;
}

.fx-comp-status-card-description-line {
    color:gray;
    padding-top:var(--padding-med);
    padding-bottom:var(--padding-med);
}
.fx-comp-status-card-action-pills-outer {
    display:flex;
    flex-direction: row;
}
.fx-comp-status-card-action-pills-outer .MuiChip-root {
    margin-right:var(--padding-med);
}

.fx-comp-status-action-pill-done {
    background-color:#B5E742 !important;
    cursor: pointer;
}

.fx-comp-status-action-pill-working {
    background-color:#EDA35E !important;
    color:white !important;
    cursor: pointer;
}
.fx-comp-status-card-action-bar {
    display:flex;
    flex-direction: row;
}
.fx-comp-status-card-avatar-bar {
    display:flex;
    flex-direction: row;
}
.fx-comp-status-card-avatar-name {
    font-size:12px;
    color:gray;
}

.fx-comp-status-card-users-row {
    display:flex;
    flex-direction: row;
}

.fx-comp-status-card-users-row-item-avatars {
    display:flex;
    flex-direction: row;
    flex-grow:1;
}

.fx-comp-status-card-users-row-item-avatars svg {
    color:var(--uxc-color-blue-teal);
    padding-right:8px;
}

.fx-comp-status-card-users-row-item-actions {
    display: flex;
    flex-grow:0;
}

.fx-comp-status-card-subtext {
    font-size:10px;
    color:#000000;
}