@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-crud-modal-name-row {
    display:flex;
    flex-direction: row;
}

.fx-comp-crud-modal-name-row div {
    margin-right:var(--padding-med);
}

