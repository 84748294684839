@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-subjective-panel-header {
    display:flex;
    flex-direction: row;
    padding:var(--padding-med);
    background-color: white;
    margin-top:var(--padding-med);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border:1px solid #cccccc;
}

.fx-comp-subjective-panel-content {
    background-color: #ffffff;
    border:1px solid #ccc;
    padding:var(--padding-med);
}

.fx-comp-subjective-panel-add-icon {
    cursor:pointer;
    color:#03205b;
}

.fx-comp-subjective-panel-view-icon {
    cursor:pointer;
    color:#03205b;
}

.fx-comp-subjective-panel-item-outer {
  background:white;
  border:1px solid #dddddd;
  border-radius:4px;
  display:flex;
  flex-direction: row;
  padding:var(--padding-med);
  margin-bottom:var(--padding-med);
}

.fx-comp-subjective-panel-view-name {
  flex-grow:1;
}

.fx-comp-subjective-panel-item-right-actions {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
}