.fx-page-auth-login-login-form-wrap .fx-global-form-row, .fx-page-auth-login-register-form-wrap .fx-global-form-row {
    width:100%;
    display:flex;
}
.fx-page-onboarding-wizard-row-names {
    display:flex;
    flex-direction:row;
    column-gap: 16px;
}
.fx-page-onboarding-wizard-row-names > div {
    width:50%;
}
.fx-page-onboarding-wizard-row-citystatezip {
    display:flex;
    flex-direction:row;
    column-gap: 16px;
}
.fx-page-onboarding-wizard-row-citystatezip-item-1 {
    width:50%;
}
.fx-page-onboarding-wizard-row-citystatezip-item-2 {
    width:25%;
    margin-top:16px;
}
.fx-page-onboarding-wizard-row-citystatezip-item-3 {
    width:25%;
}
.fx-page-onboarding-wizard-row-accountlevel {
    display:flex;
    flex-direction:row;
    column-gap: 16px;
}
.fx-page-onboarding-wizard-row-accountlevel-item {
    width:25%;
}

.fx-page-onboarding-wizard-row-accountlevel .MuiCardContent-root {
    min-height:200px;
}