@import '../CSSLibrary/UXMGRVariables.css';

.fx-user-assign-popover-container {
    padding:var(--padding-med);
    min-width:300px;
}

.fx-user-assign-popover-row {
    display:flex;
    flex-direction:row;
    column-gap:var(--padding-med);
}

