.fx-comp-quick-create-objective-form [role="combobox"] {
    background-color: white;
}

.fx-comp-quick-create-objective-form input {
    background-color: white;
}

.fx-comp-quick-create-objective-form button {
    background-color: white;
}

.fx-comp-quick-create-objective-text-area-description {
    background-color: white;
}

.fx-comp-quick-create-objective-form-header {
    display:flex;
    flex-direction: row;
    padding:16px;
    background-color: #ccc;
    border-radius:4px;
}

.fx-comp-quick-create-objective-form-header .fx-comp-nesting-expand-icon {
    background: transparent;
}

.fx-comp-quick-create-objective-fields-row {
    display:flex;
    flex-direction:row;
    column-gap: 10px;
}

.fx-comp-quick-create-objective-related-project-field {
    min-width:200px;
}

.fx-comp-quick-create-objective-description-field {
    min-width:300px;
}