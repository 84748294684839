@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-anchor-comp-assign-item {
    border-radius:4px;
    padding:var(--padding-med);
    border:1px solid #f1f1f1;
    display:flex;
    flex-direction:row;
}

.fx-comp-anchor-comp-assign-img-portion {
    display:flex;
    width:200px;
}

.fx-comp-anchor-comp-assign-img-title-portion {
    display:flex;
    flex-direction:column;
    flex-grow:1;
}