.fx-comp-quick-create-status-form {
    background-color: white;
    border-radius: 4px;
    padding:var(--padding-lg);
}

.fx-comp-quick-create-status-form [role="combobox"] {
    background-color: white;
}

.fx-comp-quick-create-status-form input {
    background-color: white;
}

.fx-comp-quick-create-status-form button {
    background-color: white;
}

.fx-comp-quick-create-status-text-area-description {
    background-color: white;
}