@import '../CSSLibrary/UXMGRVariables.css';

.fx-bottom-tray-container {
    display:flex;
    flex-direction: row;
    width:100%;
    padding-left:var(--padding-lg);
    padding-right:var(--padding-lg);
}

.fx-bottom-tray-left-items {
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
}

.fx-bottom-tray-right-items {
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
}