@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-collapsible-container-item-count-wrap {
    background:#03205b;
    color:white;
    margin-left:var(--padding-med);
    padding:2px;
    min-width:20px;
    text-align: center;
    border-radius:50px;
}