@import '../CSSLibrary/UXMGRVariables.css';

.fx-comp-anchor-device-assign-item {
    display:flex;
    flex-direction:row;
    column-gap:var(--padding-med);
}
.fx-comp-anchor-device-assign-item-icon-portion {
    display:flex;
    width:40px;
}
.fx-comp-anchor-device-assign-item-title-portion {
    display:flex;
    flex-grow:1;
    flex-direction: column;
}
.fx-comp-anchor-device-assign-item-title-portion button {
    margin-top:var(--padding-med);
}