@import '../CSSLibrary/UXMGRVariables.css';

/*
.fx-comp-feature-carousel-container {
    display:inline-flex;
    width:100%;
}

.fx-comp-feature-carousel-item-outer {
    width:18%;
    padding:var(--padding-med);
}

.fx-comp-feature-carousel-toolset-l, .fx-comp-feature-carousel-toolset-r {
    width:5%;
}

*/

.fx-comp-feature-carousel-container {
    position: relative;
    width: 100%;
    overflow: hidden; /* Ensures overflow is hidden */
    display: flex;
    align-items: center;
    padding: 0 50px; /* Adjust this value to make room for the arrows */
    box-sizing: border-box; /* Include padding and border in the element's total width and height */
  }
  
  .fx-comp-feature-carousel-content {
    display: flex;
    transition: transform 0.5s ease-in-out;
    width: 100%; /* Make sure it takes the full width of the container */
  }
  
  .fx-comp-feature-carousel-item-outer {
    flex: 0 0 25%; /* Ensures 4 items are visible at a time */
    box-sizing: border-box; /* Ensure padding and border are included in width calculations */
    margin:10px;
  }
  
  .fx-comp-feature-carousel-toolset-l,
  .fx-comp-feature-carousel-toolset-r {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    z-index: 1;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7); /* Optional: background for better visibility */
    border-radius: 50%; /* Optional: make the arrow buttons circular */
  }
  
  .fx-comp-feature-carousel-toolset-l {
    left: 10px; /* Position arrow outside the padding area */
  }
  
  .fx-comp-feature-carousel-toolset-r {
    right: 10px; /* Position arrow outside the padding area */
  }

  .fx-comp-feature-carousel-content img {
    max-height:150px;
  }